<template>
  <div class="grid-margin stretch-card d-flex">
    <div class="grid-margin col-sm-4 col-5">
      <div class="card">
        <div class="compangies card-body">
          <h4 class="card-title">
            Campaigns{{ items.length == 0 ? ": 0" : null }}
          </h4>
          <div class="template-demo">
            <table class="table mb-0" v-if="items">
              <tbody v-if="showItems">
                <tr
                  v-for="(item, idx) in items"
                  :key="item.id"
                  :class="{ active: idx == index }"
                  @click="updateIndex(idx)"
                >
                  <div class="d-flex flex-column">
                    <span class="time"
                      >created at
                      {{
                        $moment.unix(item.created).format("MM-DD-YYYY HH:mm:ss")
                      }}</span
                    >
                    {{ item.name }}
                  </div>
                  <i class="clickable mdi mdi-apple-keyboard-control"></i>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column ml-sm-3 ml-0 col-sm-8 col-7" v-if="item">
      <div class="stretch-card grid-margin">
        <div class="card bg-info card-img-holder">
          <div class="card-body">
            <img
              src="@/assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4
              class="d-flex justify-content-between align-items-center font-weight-normal mb-3 text-white"
            >
              Caller ID: {{ item.id }}
            </h4>
            <h3 class="mb-5 text-white">{{item.name}}</h3>
            <div class="mb-5" style="position: relative; z-index: 1000">
              <div class="d-flex justify-content-between mb-2 text-white">
                <small>Progress</small>
                <small>{{ item.processed_dnis }}/{{ item.total_dnis }}</small>
              </div>
              <b-progress
                :value="item.progress"
                height="1rem"
                show-progress
              ></b-progress>
            </div>
            <!-- <h6 class="card-text text-white">
              <i class="fa fa-send mr-1" />Recipients: {{item.processed_dnis}}
            </h6> -->
            <router-link class="d-block card-text text-white" :to="{ name: 'audio-manage', params: { name: getMedia(item.media_id) } }" v-if="getMedia(item.media_id)">
              <i class="fa fa-microphone mr-1" /> {{ getMedia(item.media_id) }}
            </router-link>
            <router-link :to="{ name: 'groups', params: { name: item.groups } }"
              class="card-text text-white"
              v-if="item.groups && item.groups.length > 0"
            >
              <i class="fa fa-users mr-1" />{{
                item.groups.split(",").join(", ")
              }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="card traffic" v-show="item.progress">
        <div class="card-body">
          <h4 class="card-title">Campaign Recipient Results</h4>
          <donutC3 :columns="stat" ref="stat" v-if="stat" />
          <Loader v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import doughnutChart from "@/components/charts/dashboard/doughnutChart";
import { mapGetters } from "vuex";
import donutC3 from "@/components/charts/c3-chart/donut-c3";
import Loader from "@/components/Loader";

export default {
  components: {
    donutC3, // doughnutChart,
    Loader,
  },
  data() {
    return {
      index: 0,
      show: false,
      stat: [],
      count: 0,
      start: false,
      showItems: false
    };
  },
  async mounted() {
    await this.$store.dispatch("loadItems");
    this.$store.dispatch("loadFiles", "info");
    const id = this.$route.params.id;
    if (id) {
      this.items.forEach((el, index) => {
        if (el.id == id) {
          this.updateIndex(index);
        }
      });
    } else {
      this.updateIndex(0);
    }
  },
  computed: {
    ...mapGetters(["items", "loading", "admin", "groups", "audios"]),
    item() {
      return this.items[this.index];
    },
  },
  methods: {
    updateIndex(idx) {
      this.count = 0;
      this.show = false;
      this.index = idx;
      this.stat = null;
      setTimeout(() => {
        this.show = true;
      }, 100);
      this.loadLog();
    },
    async loadLog() {

      let time = this.count > 0 ? 5000 : 0;
      let loadData = true;
      if (!this.start && this.count == 0) {
        this.count = 1;
      }
      if (this.start && this.count == 0) {
        time = 0;
        loadData = false;
        this.start = false;
      }
      if (this.count > 0) {
        this.start = true;
      }
      if (loadData) {
        setTimeout(async () => {
          let statuses = [
            ["Success"],
            ["Unsuccessful Delivery"],
            ["Unable to Detect Voicemail"],
            ["Calling timeout reached"],
            ["Exception caught"],
            ["In progress"],
          ];
          let completed;
          await this.$axios(`/campaigns/calls/${this.item.id}`).then((res) => {
            completed = res.data.calls[0].completed;
            res.data.calls.forEach((el) => {
              statuses[el.status].push(1);
            });
          });
          statuses.forEach((item) => {
            const length = item.length - 1;
            item[0] += ` - ${length}`;
          });
          this.stat = statuses;
          setTimeout(() => {
            this.$refs.stat.init();
          }, 150);
          this.checkAvailable(completed);
        }, time);
      }
      this.showItems = true
    },
    checkAvailable(completed) {
      if (completed && this.$route.path == "/reporting") {
        this.loadLog();
      }
    },
    getMedia(id) {
      let mediaName;
      this.audios.forEach((el) => {
        if (el.media_id == id) {
          mediaName = el.text;
        }
      });
      return mediaName;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.loader {
  left: 50%;
}
.compangies {
  padding: 20px 15px;
  tbody tr {
    cursor: pointer;
    border-bottom: 1px solid rgba(169, 169, 169, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.active {
      border-bottom: 1px solid rgba(2, 171, 254, 0.5);
      // background: rgba(2, 171, 254, 0.5);
      .clickable {
        color: rgba(2, 171, 254, 1);
      }
    }
    & > div {
      font-size: 14px;
      .time {
        font-size: 8px;
      }
    }
    .clickable {
      font-size: 24px;
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}
.traffic {
  min-height: 368px;
  @media (max-width: 525px) {
    min-height: auto;
  }
}
</style>
